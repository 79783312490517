










import { Component, Vue } from 'vue-property-decorator';

import HeroAlt from '@/components/sections/HeroAlt.vue';
import NewsAlt from '@/components/sections/NewsAlt.vue';
import InfoAlt from '@/components/sections/InfoAlt.vue';

@Component({
  components: {
    HeroAlt,
    NewsAlt,
    InfoAlt
  },
  metaInfo: () => ({
    title: 'Recent Blogs'
  })
})
export default class Blogs extends Vue {
  
}
