
<template>
  <base-section
    id="news"
    space="72"
  >
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          md="6"
        >
          <news-card
            v-for="(article, i) in articles"
            :key="i"
            :divider="i + 1 < articles.length"
            class="mb-12"
            v-bind="article"
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <news-search />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionNews',

    components: {
      NewsCard: () => import('@/components/news/Card'),
      NewsSearch: () => import('@/components/news/Search'),
    },

    data: () => ({
      articles: [
        {
          icon: 'mdi-text',
          category: 'Experiences',
          title: 'Welcome Colin, Our Second Son',
          text: '',
          src: 'https://images.unsplash.com/photo-1511948374796-056e8f289f34?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80',
          slug: 'welcome-colin-our-second-son',
        },
        {
          icon: 'mdi-text',
          category: 'Experiences',
          title: 'Welcome Kelton to the World',
          text: '',
          src: 'https://images.unsplash.com/photo-1470116945706-e6bf5d5a53ca?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NHx8cHJlZ25hbmN5fGVufDB8fDB8&auto=format&fit=crop&w=500&q=60',
          slug: 'welcome-kelton-to-the-world',
        },
        {
          icon: 'mdi-text',
          category: 'Experiences',
          title: 'I Am Becoming a Father!!!',
          text: '',
          src: 'https://images.unsplash.com/photo-1527620743113-fac8dd0f8d17?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80',
          slug: 'i-am-becoming-a-father',
        },
        {
          icon: 'mdi-text',
          category: 'Experiences',
          title: 'We\'re Married!',
          text: '',
          src: 'https://images.unsplash.com/photo-1548889918-9b24c0bf5333?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=633&q=80',
          slug: 'were-married',
        },
        {
          icon: 'mdi-text',
          category: 'Experiences',
          title: 'I Got a Job and a Relationship!',
          text: '',
          src: 'https://images.unsplash.com/photo-1558624153-392091714599?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80',
          slug: 'i-got-a-job-and-a-relationship',
        },
        {
          icon: 'mdi-text',
          category: 'Experiences',
          title: 'I Dropped Out of College...',
          text: '',
          src: 'https://images.unsplash.com/photo-1589668798191-efb95b5cbd93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=701&q=80',
          slug: 'dropped-out-of-college',
        },
        {
          icon: 'mdi-text',
          category: 'Experiences',
          title: 'I\'m Going to College!!',
          text: '',
          src: 'https://images.pexels.com/photos/256490/pexels-photo-256490.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
          slug: 'im-going-to-college',
        },
        {
          icon: 'mdi-text',
          category: 'Experiences',
          title: 'The Foster Care System Is Horrible',
          text: '',
          src: 'https://images.pexels.com/photos/1529360/pexels-photo-1529360.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
          slug: 'the-foster-care-system-is-horrible',
        },


      ],
    }),
  }
</script>
